import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import localforage from "localforage";
import "../css/create.css";
import BoothDataDisplay from "./BoothDataDisplay";
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "./Sidebar";

const CreateBoothForm = () => {
  const [formData, setFormData] = useState({
    userId: "",
    constituency: "",
    booth: localStorage.getItem("selectedBooth"),
    timeSlot: "",
    polledVotes: "",
    favVotes: "",
    ubtVotes: "",
  });
  const [acName, setAcName] = useState("");
  const [sampleName, setSampleName] = useState(false);
  const [booths, setBooths] = useState([]);
  const [boothData, setBoothData] = useState([]);
  const [userEmail, setUserEmail] = useState(""); // State to store user email
  const [selectedAc, setSelectedAc] = useState(""); // State to store selected AC
  const [userRoles, setUserRoles] = useState([]); // State to store user roles
  const componentRef = useRef();

  useEffect(() => {
    localforage
      .getItem("role")
      .then((roles) => {
        if (roles) {
          const acName = roles;
          fetchBoothsByConstituency(acName);
        } else {
          console.error("Error: No roles found in local storage.");
        }
      })
      .catch((error) => {
        console.error("Error fetching AC name from local storage:", error);
      });

    // Fetch user email
    localforage.getItem("email").then((email) => {
      setUserEmail(email || "");
    });

    // Fetch AC names
    localforage.getItem("roles").then((roles) => {
      if (roles) {
        setUserRoles(roles); // Set user roles
        setAcName(roles[0]); // Set the first AC name as default
      }
    });
  }, []);

  useEffect(() => {
    const booth5 = localStorage.getItem("selectedBooth");
    console.log("booth5::: ", booth5);
    setFormData({
      userId: "",
      constituency: "",
      booth: booth5,
      timeSlot: "",
      polledVotes: "",
      favVotes: "",
    });
  }, []);

  useEffect(() => {
    const booth5 = localStorage.getItem("selectedBooth");
    console.log("booth5::: ", booth5);
    setFormData({
      userId: "",
      constituency: "",
      booth: booth5,
      timeSlot: "",
      polledVotes: "",
      favVotes: "",
    });
  }, [sampleName]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAcChange = (e) => {
    const { value } = e.target;
    setSelectedAc(value);
    fetchBoothsByConstituency(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userId = await localforage.getItem("ID");
      localStorage.setItem("selectedBooth", formData.booth);
      const response = await axios.post(
        "https://booth-analysis-server.onrender.com/api/booths/create",
        {
          ...formData,
          userId: userId,
          constituency: selectedAc,
        }
      );
      console.log(response.data);
      setFormData({
        userId: "",
        constituency: "",
        booth: "",
        timeSlot: "",
        polledVotes: "",
        favVotes: "",
        ubtVotes: "",
      });
      setSampleName(!sampleName);
    } catch (error) {
      console.error("Error creating booth:", error);
    }
  };

  const fetchBoothsByConstituency = async (selectedAc) => {
    try {
      const response = await axios.get(
        `https://booth-analysis-server.onrender.com/api/booths/get-booth-names-by-constituency/${selectedAc}`
      );
      setBooths(response.data);
    } catch (error) {
      console.error("Error fetching booths by constituency:", error);
    }
  };

  useEffect(() => {
    fetchBoothData();
  }, [formData.booth]);

  const fetchBoothData = async () => {
    if (formData.booth) {
      try {
        const response = await axios.get(
          `https://booth-analysis-server.onrender.com/api/ac/get-acdata-by-booth/${formData.booth}`
        );
        setBoothData(response.data);
      } catch (error) {
        console.error("Error fetching booth data:", error);
      }
    }
  };

  const handleTimeSlotChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      timeSlot: value,
    }));
    fetchBoothData();
  };

  // Generate available time slots dynamically based on current time
  const availableSlots = [
    "09:00AM",
    "11:00AM",
    "01:00PM",
    "03:00PM",
    "05:00PM",
    "07:00PM",
    "09:00PM",
  ];
  
  const getCurrentISTTime = () => {
    const now = new Date();
    const options = { timeZone: "Asia/Kolkata", hour12: false };
    return now.toLocaleTimeString("en-US", options).slice(0, 5);
  };
  
  // Function to convert time string to minutes past midnight
  const convertToMinutes = (timeString) => {
    const [time, period] = timeString.split(/(?=[AP]M)/); // Split time and period
    let [hours, minutes] = time.split(":").map(Number); // Extract hours and minutes
    if (period === "PM" && hours < 12) hours += 12; // Adjust hours for PM
    if (period === "AM" && hours === 12) hours = 0; // Adjust hours for midnight
    return hours * 60 + minutes; // Convert to minutes
  };
  
  // Filter available time slots based on current IST time
  const currentISTTimeInMinutes = convertToMinutes(getCurrentISTTime());
  const filteredSlots = availableSlots.filter((slot) => {
    const slotInMinutes = convertToMinutes(slot);
    return slotInMinutes < currentISTTimeInMinutes;
  });
  

  return (
    <div className="main-class">
      <Sidebar />
      <div className="data-container">
        <div className="export-button">
          <ReactToPrint
            trigger={() => (
              <FontAwesomeIcon
                icon={faFilePdf}
                className="font-pdf"
                size="2x"
              />
            )}
            content={() => componentRef.current}
            pageStyle={`@page { margin: 0mm 10mm; }`}
            documentTitle="YouTube Trends in Maharashtra"
            removeAfterPrint={true}
          />
        </div>
        <h2>Booth Dashboard</h2>

        <div className="main-ac-container">
          <h2>
            Welcome!! {userEmail && userEmail.split("@")[0].toUpperCase()}
          </h2>
          <div>
            <select
              name="ac"
              value={selectedAc}
              onChange={handleAcChange}
              className="input-fields"
            >
              <option value="">Select AC</option>
              {userRoles.map((role, index) => (
                <option key={index} value={role}>
                  {role}
                </option>
              ))}
            </select>
          </div>
        </div>
        <form className="data-form" onSubmit={handleSubmit}>
          <div>
            <select
              name="booth"
              value={formData.booth}
              onChange={handleChange}
              className="input-fields2"
            >
              <option value="">Select Booth</option>
              {booths.map((booth, index) => (
                <option key={index} value={booth}>
                  {booth}
                </option>
              ))}
            </select>
          </div>
          <div className="fields-div">
            <select
              name="timeSlot"
              value={formData.timeSlot}
              onChange={handleTimeSlotChange}
              className="input-fields"
            >
              <option value="">Select Time Slot</option>
              {filteredSlots.map((slot, index) => (
                <option key={index} value={slot}>
                  {slot}
                </option>
              ))}
            </select>
            <input
              type="number" // Change input type to number
              name="polledVotes"
              className="input-fields"
              placeholder="Polled Votes"
              value={formData.polledVotes}
              onChange={handleChange}
              min="0" // Set minimum value to 0
              max="1500" // Set maximum value to 1500
            />
            <input
              type="number" // Change input type to number
              name="favVotes"
              className="input-fields"
              placeholder="Shivsena Votes"
              value={formData.favVotes}
              onChange={handleChange}
              min="0" // Set minimum value to 0
              max={formData.polledVotes} // Set maximum value to Total Votes
            />
            <input
              type="number" // Change input type to number
              name="ubtVotes"
              className="input-fields"
              placeholder="UBT Votes"
              value={formData.ubtVotes}
              onChange={handleChange}
              min="0" // Set minimum value to 0
              max={formData.polledVotes} // Set maximum value to Total Votes
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>

      <div id="pdf-content" ref={componentRef}>
        <div className="display-data2">
          <div className="display-data">
            <h2>Booth Data - {formData.booth}</h2>
            <table className="display-table-data2">
              <thead>
                <tr>
                  <th>Booth</th>
                  <th>Total Votes</th>
                  <th>AC Incharge Name</th>
                  <th>AC Incharge Contact</th>
                  <th>Booth Incharge Name</th>
                  <th>Booth Incharge Contact</th>
                </tr>
              </thead>
              <tbody>
                {boothData.map((data, index) => (
                  <tr key={index}>
                    <td>{data.booth}</td>
                    <td>{data.totalVotes}</td>
                    <td>{data.acInchargeName}</td>
                    <td>{data.acInchargeContact}</td>
                    <td>
                      {data.boothInchargeName.map((incharge, i) => (
                        <div key={i}>{incharge.name}</div>
                      ))}
                    </td>
                    <td>
                      {data.boothInchargeName.map((incharge, i) => (
                        <div key={i}>{incharge.contact}</div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="display-data">
          {formData.booth && <BoothDataDisplay booth={formData.booth} />}
        </div>
      </div>
    </div>
  );
};

export default CreateBoothForm;
