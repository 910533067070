import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { styles } from "react-select";
import "../css/signup.css";
import img from "../STC_logo-01.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [roles, setRoles] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const history = useNavigate();

  const handleRoleChange = (selectedRoles) => {
    setRoles(selectedRoles.map((role) => role.value));
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://booth-analysis-server.onrender.com/api/auth/signup",
        {
          email,
          password,
          roles,
        }
      );
      console.log("Sign Up success:", response.data.message);
      history("/viewboothdata");
    } catch (error) {
      console.error("Sign Up failed:", error.response.data.message);
    }
  };

  const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "mod", label: "Moderator" },
    { value: "user", label: "User" },
    { value: "120-Sinnar", label: "120-Sinnar" },
    { value: "123-Nashik East", label: "123-Nashik East" },
    { value: "124-Nashik (Central)", label: "124-Nashik (Central)" },
    { value: "125-Nashik West", label: "125-Nashik West" },
    { value: "126-Deolali (SC)", label: "126-Deolali (SC)" },
    { value: "127-Igatpuri (ST)", label: "127-Igatpuri (ST)" },
    { value: "140- Ambarnath (SC)", label: "140- Ambarnath (SC)" },
    { value: "141-Ulhasnagar", label: "141-Ulhasnagar" },
    { value: "142-Kalyan East", label: "142-Kalyan East" },
    { value: "143-Dombivali", label: "143-Dombivali" },
    { value: "144-Kalyan Rural", label: "144-Kalyan Rural" },
    { value: "145-Meera Bhayandar", label: "145-Meera Bhayandar" },
    { value: "146-Ovala Majiwada", label: "146-Ovala Majiwada" },
    { value: "147-Kopri-Pachpakhadi", label: "147-Kopri-Pachpakhadi" },
    { value: "148-Thane", label: "148-Thane" },
    { value: "149-Mumbra Kalwa", label: "149-Mumbra Kalwa" },
    { value: "150-Airoli", label: "150-Airoli" },
    { value: "151-Belapur", label: "151-Belapur" },
    {
      value: "158-Jogeshwari East",
      label: "158-Jogeshwari East",
    },
    { value: "159-Dindoshi", label: "159-Dindoshi" },
    { value: "163-Goregaon", label: "163-Goregaon" },
    { value: "164-Varsova", label: "164-Varsova" },
    { value: "165-Andheri West", label: "165-Andheri West" },
    { value: "166-Andheri East", label: "166-Andheri East" },
    { value: "172-Anushakti Nagar", label: "172-Anushakti Nagar" },
    { value: "173-Chembur", label: "173-Chembur" },
    { value: "178-Dharavi (SC)", label: "178-Dharavi (SC)" },
    { value: "179-Sion Koliwada", label: "179-Sion Koliwada" },
    { value: "180-Wadala", label: "180-Wadala" },
    { value: "181-Mahim", label: "181-Mahim" },
    { value: "182-Worli", label: "182-Worli" },
    { value: "183-Shivadi", label: "183-Shivadi" },
    { value: "184-Byculla", label: "184-Byculla" },
    { value: "185-Malabar Hill", label: "185-Malabar Hill" },
    { value: "186-Mumbadevi", label: "186-Mumbadevi" },
    { value: "187-Colaba", label: "187-Colaba" },
  ];

  const customStyles = {
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display:
        state.selectProps.value && state.selectProps.value.length > 0
          ? "block"
          : "none",
    }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "2px solid #4CAF50" : "2px solid #ccc",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "0 0 5px rgba(76, 175, 80, 0.7)" : "none",
      cursor: "pointer",
    }),
  };

  return (
    <>
      <div className="sidebar-container"></div>
      <div className="container">
        <div className="login" style={{ width: "350px", marginTop: "120px" }}>
          <img src={img} className="img" alt="STC Logo" />
          <h1>Sign Up</h1>
          <form onSubmit={handleSubmit} style={{ alignItems: "center" }}>
            <input
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ width: "300px" }}
            />
            <div
              className="password-input"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
                marginBottom: "20px",
              }}
            >
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{ width: "300px" }}
              />
              <div
                className="eye-icon"
                onClick={toggleShowPassword}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </div>
            </div>
            <div
              className="roles"
              style={{
                width: "300px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label style={{ marginBottom: "5px", textAlign: "center" }}>
                Select Roles
              </label>
              <Select
                isMulti
                options={roleOptions}
                value={roleOptions.filter((role) => roles.includes(role.value))}
                onChange={handleRoleChange}
                styles={customStyles}
              />
            </div>

            <div className="bttn">
              <button type="submit" className="button">
                Sign Up
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignUp;
