import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/boothDashboard.css";
import Chart from "chart.js/auto";
import Sidebar from "./Sidebar";

const BoothDashboard = () => {
  const [acNames, setAcNames] = useState([]);
  const [pcNames, setPcNames] = useState([]);
  const [boothNames, setBoothNames] = useState([]);
  const [selectedAc, setSelectedAc] = useState("");
  const [pcTotal, setPcTotal] = useState(0);
  const [acTotal, setAcTotal] = useState(0);
  const [boothTotal, setBoothTotal] = useState(0);
  const [totalVotes, setTotalVotes] = useState(0);
  const [totalPolledVotes, setTotalPolledVotes] = useState(0);
  const [totalFavVotes, setTotalFavVotes] = useState(0);
  const [totalUbtVotes, setTotalUbtVotes] = useState(0);
  const [boothTypeVotes, setBoothTypeVotes] = useState([]);
  const [favVotesData, setFavVotesData] = useState([]);
  const [ubtVotesData, setUbtVotesData] = useState([]);
  const [otherVotesData, setOtherVotesData] = useState([]);
  const [pcData, setPcData] = useState([]);
  const [selectedPC, setSelectedPC] = useState(null);
  const [expandedPc, setExpandedPc] = useState(null);

  // Fetch AC Names
  useEffect(() => {
    axios
      .get("https://booth-analysis-server.onrender.com/api/booths/get-ac-names")
      .then((response) => setAcNames(response.data))
      .catch((error) => console.error("Error fetching AC names:", error));
  }, []);

  // Fetch PC Names
  useEffect(() => {
    axios
      .get("https://booth-analysis-server.onrender.com/api/booths/get-pc-names")
      .then((response) => setPcNames(response.data))
      .catch((error) => console.error("Error fetching PC names:", error));
  }, []);

  // Fetch Booth Names by Constituency
  const fetchBoothNames = (constituencyName) => {
    axios
      .get(
        `https://booth-analysis-server.onrender.com/api/booths/get-booth-names-by-constituency/${constituencyName}`
      )
      .then((response) => setBoothNames(response.data))
      .catch((error) => console.error("Error fetching booth names:", error));
  };

  // Fetch PC Total Count
  useEffect(() => {
    axios
      .get("https://booth-analysis-server.onrender.com/api/booths/get-pc-total")
      .then((response) => setPcTotal(response.data.totalCount))
      .catch((error) => console.error("Error fetching PC total count:", error));
  }, []);

  // Fetch AC Total Count
  useEffect(() => {
    axios
      .get("https://booth-analysis-server.onrender.com/api/booths/get-ac-total")
      .then((response) => setAcTotal(response.data.totalCount))
      .catch((error) => console.error("Error fetching AC total count:", error));
  }, []);

  // Fetch Booth Total Count
  useEffect(() => {
    axios
      .get("https://booth-analysis-server.onrender.com/api/booths/get-booth-total")
      .then((response) => setBoothTotal(response.data.totalCount))
      .catch((error) =>
        console.error("Error fetching Booth total count:", error)
      );
  }, []);

  // Fetch Total Votes
  useEffect(() => {
    axios
      .get("https://booth-analysis-server.onrender.com/api/booths/total-votes")
      .then((response) => setTotalVotes(response.data.totalCount))
      .catch((error) => console.error("Error fetching total votes:", error));
  }, []);

  // Fetch Total Polled Votes
  useEffect(() => {
    axios
      .get("https://booth-analysis-server.onrender.com/api/booths/total-polled-votes")
      .then((response) => setTotalPolledVotes(response.data.totalCount))
      .catch((error) =>
        console.error("Error fetching total polled votes:", error)
      );
  }, []);

  // Fetch Total Fav Votes
  useEffect(() => {
    axios
      .get("https://booth-analysis-server.onrender.com/api/booths/total-fav-votes")
      .then((response) => setTotalFavVotes(response.data.totalCount))
      .catch((error) =>
        console.error("Error fetching total favorite votes:", error)
      );
  }, []);

  // Fetch Total Ubt Votes
  useEffect(() => {
    axios
      .get("https://booth-analysis-server.onrender.com/api/booths/total-ubt-votes")
      .then((response) => setTotalUbtVotes(response.data.totalCount))
      .catch((error) =>
        console.error("Error fetching total UBT votes:", error)
      );
  }, []);

  useEffect(() => {
    axios
      .get("https://booth-analysis-server.onrender.com/api/booths/total-votes-by-booth-type")
      .then((response) => {
        const data = response.data;
        setBoothTypeVotes(data);
        renderChart(data); // Call renderChart with fetched data
      })
      .catch((error) =>
        console.error("Error fetching total votes by booth type:", error)
      );
  }, []);

  useEffect(() => {
    axios
      .get("https://booth-analysis-server.onrender.com/api/booths/votes-by-fav-ubt-other-percentage")
      .then((response) => {
        const data = response.data;
        setFavVotesData(data.map((item) => item.favVotesPercentage));
        setUbtVotesData(data.map((item) => item.ubtVotesPercentage));
        setOtherVotesData(data.map((item) => item.otherVotesPercentage));
        renderOtherChart(data);
      })
      .catch((error) =>
        console.error("Error fetching votes by fav, ubt, other:", error)
      );
  }, []);

  const renderOtherChart = (data) => {
    try {
      const ctx = document.getElementById("otherVotesChart");
      if (!ctx) {
        console.error("Canvas element with id 'otherVotesChart' not found.");
        return;
      }

      // Destroy existing chart if it exists
      Chart.getChart(ctx)?.destroy();

      new Chart(ctx, {
        type: "bar",
        data: {
          labels: data.map((item) => item._id),
          datasets: [
            {
              label: "Shivsena Votes Percentage",
              data: data.map((item) => item.favVotesPercentage),
              backgroundColor: "rgba(255, 99, 71, 0.8)",
              borderColor: "rgba(0, 0, 0, 0.8)",
              borderWidth: 2,
            },
            {
              label: "UBT Votes Percentage",
              data: data.map((item) => item.ubtVotesPercentage),
              backgroundColor: "rgba(255, 170, 0, 0.8)",
              borderColor: "rgba(0, 0, 0, 0.8)",
              borderWidth: 2,
            },
            {
              label: "Other Votes Percentage",
              data: data.map((item) => item.otherVotesPercentage),
              backgroundColor: "rgba(0, 200, 255, 0.8)",
              borderColor: "rgba(0, 0, 0, 0.8)",
              borderWidth: 2,
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              formatter: (value, context) => {
                return value.toFixed(2) + "%";
              },
              color: "#000",
              anchor: "end",
              align: "end",
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: function (value, index, values) {
                  return value.toFixed(2) + "%";
                },
              },
            },
          },
        },
      });
    } catch (error) {
      console.error("Error rendering other votes chart:", error);
    }
  };

  const renderChart = (data) => {
    try {
      const ctx = document.getElementById("boothTypeChart");
      if (!ctx) {
        console.error("Canvas element with id 'boothTypeChart' not found.");
        return;
      }

      // Destroy existing chart if it exists
      Chart.getChart(ctx)?.destroy();

      new Chart(ctx, {
        type: "bar",
        data: {
          labels: data.map((item) => item._id),
          datasets: [
            {
              label: "Polled Votes Percentage",
              data: data.map((item) => item.polledVotesPercentage),
              backgroundColor: [
                "rgba(21, 102, 255, 0.8)",
                "rgba(21, 102, 255, 0.8)",
                "rgba(21, 102, 255, 0.8)",
                "rgba(21, 102, 255, 0.8)",
              ],
              borderColor: [
                "rgba(0, 0, 0, 0.8)",
                "rgba(0, 0, 0, 0.8)",
                "rgba(0, 0, 0, 0.8)",
                "rgba(0, 0, 0, 0.8)",
              ],
              borderWidth: 2,
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              formatter: (value, context) => {
                return value.toFixed(2) + "%";
              },
              color: "#000",
              anchor: "end",
              align: "end",
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: function (value, index, values) {
                  return value.toFixed(2) + "%";
                },
              },
            },
          },
        },
      });
    } catch (error) {
      console.error("Error rendering chart:", error);
    }
  };

  const handleAcChange = (e) => {
    const { value } = e.target;
    setSelectedAc(value);
    fetchBoothNames(value);
  };

  const boothType = ["A", "B", "C", "D"];

  useEffect(() => {
    axios
      .get("https://booth-analysis-server.onrender.com/api/booths/get-all-pcs-data")
      .then((response) => setPcData(response.data))
      .catch((error) => console.error("Error fetching PC data:", error));
  }, []);

  const handlePcClick = (pcId) => {
    setExpandedPc((prevExpandedPc) => (prevExpandedPc === pcId ? null : pcId));
  };

  return (
    <>
    <Sidebar />
    <div className="main-data-container">
      <h1>Loksabha Election 2024</h1>
      {/* <div className="main-dash">
        <div className="select-container">
          <h2 className="select-label">Select PC</h2>
          <select>
            <option value="">Select PC</option>
            {pcNames.map((pc, index) => (
              <option key={index} value={pc}>
                {pc}
              </option>
            ))}
          </select>
        </div>

        <div className="select-container">
          <h2 className="select-label">Select AC</h2>
          <select value={selectedAc} onChange={handleAcChange}>
            <option value="">Select AC</option>
            {acNames.map((ac, index) => (
              <option key={index} value={ac}>
                {ac}
              </option>
            ))}
          </select>
        </div>

        <div className="select-container">
          <h2 className="select-label">Select Booth</h2>
          <select>
            <option value="">Select Booth</option>
            {boothNames.map((booth, index) => (
              <option key={index} value={booth}>
                {booth}
              </option>
            ))}
          </select>
        </div>
        <div className="select-container">
          <h2 className="select-label">Select Booth Type</h2>
          <select>
            <option value="">Select Booth Type</option>
            {boothType.map((booth, index) => (
              <option key={index} value={booth}>
                {booth}
              </option>
            ))}
          </select>
        </div>
      </div> */}
      <div className="main-count-container">
        <div className="count-container">
          <h3>Total PC Count</h3>
          <div className="count-box">{pcTotal}</div>
        </div>
        <div className="count-container">
          <h3>Total AC Count</h3>
          <div className="count-box">{acTotal}</div>
        </div>
        <div className="count-container">
          <h3>Total Booth Count</h3>
          <div className="count-box">{boothTotal}</div>
        </div>
      </div>
      <div className="main-count-container">
        <div className="count-container">
          <h3>Total Electors</h3>
          <div className="count-box">{totalVotes}</div>
        </div>
        <div className="count-container">
          <h3>Total Polled Votes</h3>
          <div className="count-box">{totalPolledVotes}</div>
        </div>
        <div className="count-container">
          <h3>Total Shivsena Votes</h3>
          <div className="count-box">{totalFavVotes}</div>
        </div>
        <div className="count-container">
          <h3>Total UBT Votes</h3>
          <div className="count-box">{totalUbtVotes}</div>
        </div>
      </div>
      <div className="main-chart-container">
        <div className="chart-container">
          <canvas id="boothTypeChart"></canvas>
        </div>
        <div className="chart-container">
          <canvas id="otherVotesChart"></canvas>
        </div>
      </div>
      <div className="main-table-container">
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>PC</th>
                <th>Total Electors</th>
                <th>Polled Votes</th>
                <th>Shivsena Votes</th>
                <th>UBT Votes</th>
                <th>Polled Votes%</th>
                <th>Shivsena Votes%</th>
                <th>UBT Votes%</th>
              </tr>
            </thead>
            <tbody>
              {pcData.map((pcItem) => {
                let pcTotalElectors = 0;
                let pcTotalPolledVotes = 0;
                let pcTotalShivsenaVotes = 0;
                let pcTotalUBTVotes = 0;

                pcItem.acs.forEach((ac, index) => {
                  const data = pcItem.data[index];
                  pcTotalElectors += parseInt(data.totalVotes, 10);
                  pcTotalPolledVotes += parseInt(data.polledVotes, 10);
                  pcTotalShivsenaVotes += parseInt(data.favVotes, 10);
                  pcTotalUBTVotes += parseInt(data.ubtVotes, 10);
                });

                return (
                  <React.Fragment key={pcItem._id}>
                    <tr
                      onClick={() => handlePcClick(pcItem._id)}
                      className="pc-row"
                    >
                      <td style={{fontWeight:"600"}}>
                        {pcItem._id}
                        {expandedPc === pcItem._id ? (
                          <span onClick={(e) => e.stopPropagation()}>-</span>
                        ) : (
                          <span onClick={(e) => e.stopPropagation()}>+</span>
                        )}
                      </td>
                      <td style={{fontWeight:"600"}}>{pcTotalElectors}</td>
                      <td style={{fontWeight:"600"}}>{pcTotalPolledVotes}</td>
                      <td style={{fontWeight:"600"}}>{pcTotalShivsenaVotes}</td>
                      <td style={{fontWeight:"600"}}>{pcTotalUBTVotes}</td>
                      <td style={{fontWeight:"600"}}>
                        {((pcTotalPolledVotes / pcTotalElectors) * 100).toFixed(
                          2
                        )}
                        %
                      </td>
                      <td style={{fontWeight:"600"}}>
                        {(
                          (pcTotalShivsenaVotes / pcTotalPolledVotes) *
                          100
                        ).toFixed(2)}
                        %
                      </td>
                      <td style={{fontWeight:"600"}}>
                        {((pcTotalUBTVotes / pcTotalPolledVotes) * 100).toFixed(
                          2
                        )}
                        %
                      </td>
                    </tr>
                    {expandedPc === pcItem._id &&
                      pcItem.acs.map((ac, index) => {
                        const data = pcItem.data[index];
                        return (
                          <tr key={ac} className="sub-row">
                            <td>{ac}</td>
                            <td>{data.totalVotes}</td>
                            <td>{data.polledVotes}</td>
                            <td>{data.favVotes}</td>
                            <td>{data.ubtVotes}</td>
                            {/* Calculate and display percentages */}
                            <td>
                              {(
                                (data.polledVotes / data.totalVotes) *
                                100
                              ).toFixed(2)}
                              %
                            </td>
                            <td>
                              {(
                                (data.favVotes / data.polledVotes) *
                                100
                              ).toFixed(2)}
                              %
                            </td>
                            <td>
                              {(
                                (data.ubtVotes / data.polledVotes) *
                                100
                              ).toFixed(2)}
                              %
                            </td>
                          </tr>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </>
  );
};

export default BoothDashboard;
